import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { registerExpense } from "../../redux/actions/sale";
import { ClipLoader } from "react-spinners";

const ExpenseModal = ({ showExpenseModal, setShowExpenseModal, branch }) => {
  const [amount, setAmount] = React.useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.sale);
  const [description, setDescription] = React.useState("");
  const [date, setDate] = React.useState(new Date().toISOString().slice(0, 10));

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      registerExpense(
        {
          amount,
          date,
          description,
        },
        branch,
        () => {
          setShowExpenseModal(false);
          setAmount("");
          setDescription("");
        }
      )
    );
  };

  return (
    <Modal
      onClose={() => setShowExpenseModal(false)}
      open={showExpenseModal}
      center
    >
      <form
        className="mt-2 p-2"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h5>Register An Expense</h5>
        <div className="mb-2">
          <p>Add an expense, for</p>
          <input
            type="date"
            max={new Date().toISOString().slice(0, 10)}
            className="browser-default
            custom-text-input  lighten-4"
            placeholder="Enter Date"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
            required
          />
        </div>
        <div className="mb-2 mt-2">
          <p>Enter Amount </p>
          <input
            type="number"
            className="browser-default
               custom-text-input"
            placeholder="Enter expense amount"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            min="0"
            required
          />
        </div>

        <div className="mb-2 mt-2">
          <p>Enter Description </p>
          <input
            type="text"
            className="browser-default
               custom-text-input"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            required
          />
        </div>

        <div>
          <button
            className="btn"
            type="submit"
            disabled={amount === "" || description === "" || date === ""}
          >
            {loading ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              "Submit Expense"
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ExpenseModal;
