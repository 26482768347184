import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton, HomeButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { amount } from "../../helpers/helper";
import { Dropdown, ProgressBar } from "react-materialize";
import DatePicker from "react-datepicker";
import { getSales } from "../../redux/actions/sale";
import { ClipLoader } from "react-spinners";
import { getInventoryReceipts } from "../../redux/actions/inventory";
import StockReceiptModal from "./components/StockReceiptModal";
import ConfirmDeleteInventoryReceiptModal from "./components/ConfirmDeleteInventoryReceipt";

const InventoryReceiptHistory = () => {
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [showSaleModal, setShowSaleModal] = React.useState(false);
  const dispatch = useDispatch();
  const id = useParams().id;
  const { branches } = useSelector((state) => state.branch);
  const { inventoryReceipts, loading } = useSelector(
    (state) => state.inventory
  );
  const branch = branches.find((branch) => branch._id === id);
  const [showDeleteInventoryReceiptModal, setShowDeleteInventoryReceiptModal] =
    React.useState(false);
  const useQuery = () => {
    return new URLSearchParams(window.location.search);
  };

  const query = useQuery();
  const start = query.get("start");
  const end = query.get("end");

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    setStartDate(new Date().setDate(new Date().getDate() - 1));
    setEndDate(new Date());
  }, []);

  React.useEffect(() => {
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
    }
  }, [end, start]);

  React.useEffect(() => {
    if (!id || !startDate || !endDate) return;
    navigate(
      `/inventory-receipt-history/${id}?start=${new Date(startDate)
        .toJSON()
        .slice(0, 10)}&end=${new Date(endDate).toJSON().slice(0, 10)}`
    );
    dispatch(getInventoryReceipts(id, startDate, endDate));
  }, [dispatch, endDate, id, navigate, startDate]);

  return (
    <div className="main">
      <StockReceiptModal
        showSaleModal={showSaleModal}
        setShowSaleModal={setShowSaleModal}
      />

      <ConfirmDeleteInventoryReceiptModal
        showDeleteInventoryReceiptModal={showDeleteInventoryReceiptModal}
        setShowDeleteInventoryReceiptModal={setShowDeleteInventoryReceiptModal}
        id={id}
      />
      <header className="flex full-width  space-between p-2 sales-header align-center">
        <div className="flex align-center">
          <HomeButton />
          <div>
            <h5 className="margin-0">Stock Receival History</h5>
            <>{branch?.name}</>
          </div>
        </div>

        <div className="flex align-center gap-1 ">
          <div className="flex align-center gap-1" style={{ zIndex: 100 }}>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              dateFormat={"yyyy/MM/dd"}
              selectsRange
            />
          </div>

          <button
            className="btn-small"
            onClick={() =>
              dispatch(getInventoryReceipts(id, startDate, endDate))
            }
          >
            {" "}
            Show Results
          </button>
        </div>
      </header>
      {loading && <ProgressBar />}

      {loading ? (
        <div
          className="flex column align-center justify-center full-height main-body"
          style={{ flex: 1 }}
        >
          <ClipLoader color="teal" loading={loading} size={50} />
        </div>
      ) : inventoryReceipts.length === 0 ? (
        <div
          className="flex column align-center justify-center full-height main-body"
          style={{ flex: 1 }}
        >
          <h5>No Inventory Receival Receipts Found</h5>
          <p>You have not received any stock for the selected period.</p>
        </div>
      ) : (
        <section className="p-2 main-body">
          <table className="">
            <thead>
              <tr>
                <th>#</th>
                <th>Supplier</th>
                <th>Date & Time</th>
                <th>Received By</th>
                <th>Total Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {inventoryReceipts.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <p>{item.supplier?.name}</p>
                    </td>
                    <td>
                      <p>
                        {new Date(item.createdAt).toDateString()},{" "}
                        {new Date(item.createdAt).toLocaleTimeString()}{" "}
                      </p>
                    </td>
                    <td>
                      <p>{item.receivedBy?.name} </p>
                    </td>
                    <td>
                      <h6>{amount(item.totalCost)}</h6>
                    </td>

                    <td className="">
                      <div className="flex align-center">
                        <button
                          className="btn white teal-text waves-effect waves-light  flex justify-center align-center mr-1 "
                          onClick={() => {
                            setShowSaleModal(true);
                            dispatch({
                              type: "GOT_INVENTORY_RECEIPT",
                              payload: item,
                            });
                          }}
                        >
                          View
                        </button>
                        <Dropdown
                          trigger={
                            <i className="material-symbols-outlined ">
                              more_vert
                            </i>
                          }
                        >
                          <span
                            onClick={() => {
                              setShowDeleteInventoryReceiptModal(true);
                              dispatch({
                                type: "GOT_INVENTORY_RECEIPT",
                                payload: item,
                              });
                            }}
                          >
                            Reverse Invoice
                          </span>
                          <span
                            onClick={() => {
                              const products = item.products.map((product) => {
                                const data = {
                                  expiryDate: "",
                                  manufacturer: product.product.manufacturer,
                                  name: product.product.name,
                                  price: product.basePrice,
                                  quantity: product.quantity,
                                  retailPrice: product.retailPrice,
                                  size: "200ml",
                                  total: product.basePrice * product.quantity,
                                  type: "syrup",
                                  wholesalePrice: product.wholesalePrice,
                                  _id: product.product._id,
                                };

                                return data;
                              });

                              const tab = {
                                id: 1,
                                title: "Sales Tab 1",
                                active: true,
                                products,
                                supplier: {
                                  name: "No Supplier Selected",
                                  id: "",
                                  phoneNumber: "N/A",
                                },
                              };

                              dispatch({
                                type: "GOT_INVENTORY_RECEIPT_TAB",
                                payload: tab,
                              });

                              navigate("/receive-inventory/" + branch._id);
                            }}
                          >
                            Copy Invoice
                          </span>

                          <span
                            onClick={() => {
                              const products = item.products.map((product) => {
                                const data = {
                                  expiryDate: "",
                                  manufacturer: product.product.manufacturer,
                                  name: product.product.name,
                                  price: product.basePrice,
                                  quantity: product.quantity,
                                  retailPrice: product.retailPrice,
                                  size: "200ml",
                                  total: product.basePrice * product.quantity,
                                  type: "syrup",
                                  wholesalePrice: product.wholesalePrice,
                                  _id: product.product._id,
                                };

                                return data;
                              });

                              const tab = {
                                id: 1,
                                title: "Sales Tab 1",
                                active: true,
                                products,
                                supplier: {
                                  name:
                                    item.supplier?.name ||
                                    "No Supplier Selected",
                                  id: item.supplier?._id || "",
                                  phoneNumber:
                                    item.supplier?.phoneNumber || "N/A",
                                },
                                saved: true,
                                stockReceiptId: item._id,
                              };

                              dispatch({
                                type: "GOT_INVENTORY_RECEIPT_TAB",
                                payload: tab,
                              });

                              navigate("/receive-inventory/" + branch._id);
                            }}
                          >
                            Update Invoice
                          </span>

                          <span
                            onClick={() => {
                              const products = item.products.map((product) => {
                                const data = {
                                  expiryDate: "",
                                  manufacturer: product.product.manufacturer,
                                  name: product.product.name,
                                  price: product.wholesalePrice,
                                  quantity: product.quantity,
                                  retailPrice: product.retailPrice,
                                  size: product.product.size,
                                  total:
                                    product.wholesalePrice * product.quantity,
                                  type: product.product.type,
                                  wholesalePrice: product.wholesalePrice,
                                  _id: product.product._id,
                                };

                                return data;
                              });

                              const tab = {
                                id: 1,
                                title: "Sales Tab 1",
                                active: true,
                                products,
                                customer: {
                                  name: "No Supplier Selected",
                                  id: "",
                                  phoneNumber: "N/A",
                                },
                              };

                              dispatch({
                                type: "GOT_SALES_RECEIPT_TAB",
                                payload: tab,
                              });

                              navigate("/sale/65f2b3bb6233c375f0b5d9da");
                            }}
                          >
                            Van Sale
                          </span>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
};

export default InventoryReceiptHistory;
