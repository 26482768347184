import React from "react";
import { DatePicker, Dropdown } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import QuantityPriceModal from "../../sale/QuantityPriceModal";
import ReceivedPaymentModal from "../../sale/ReceivedPaymentModal";
import { amount, amountWithoutCurrency, notify } from "../../../helpers/helper";
import {
  calculateTotal,
  decreaseProductQuantity,
  increaseProductQuantity,
  removeProduct,
  updateExpiryDate,
  updateProductOnTab,
} from "../../sale/actions";
import ConfirmReceivedStockModal from "./ConfirmReceivedStockModal";
import RIChangeParamsModal from "./RIChangeParamsModal";
import AddSupplierModal from "../../suppliers/AddSupplier";
import Modal from "react-responsive-modal";
import AddProduct from "../../../components/AddProduct";
import AddSystemProduct from "../../products/components/AddSystemProduct";
import { getBranch } from "../../../redux/actions/branches";

const RIproductListSection = ({ tab, setTabs, tabs, tabIndex }) => {
  const inputRef = React.useRef();
  const searchSupplierInputRef = React.useRef();
  const searchProductDropdownRef = React.useRef();
  const searchSupplierDropdownRef = React.useRef();
  const [searchProduct, setSearchProduct] = React.useState("");
  const [searchSupplier, setSearchSupplier] = React.useState("");
  const [productsList, setProductsList] = React.useState([]);
  const [suppliersList, setSuppliersList] = React.useState([]);
  const { branch } = useSelector((state) => state.branch);
  const { suppliers } = useSelector((state) => state.supplier);
  const [highlightedIndex, setHighlightedIndex] = React.useState(-1);
  const [showQuantityPriceModal, setShowQuantityPriceModal] =
    React.useState(false);
  const [showConfirmReceivedStockModal, setShowConfirmReceivedStockModal] =
    React.useState(false);
  React.useState(false);
  const [productIndex, setProductIndex] = React.useState(-1);
  const [searchType, setSearchType] = React.useState("product");
  const [selectedInput, setSelectedInput] = React.useState("quantity");
  const [showAddSupplierModal, setShowAddSupplierModal] = React.useState(false);
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [showAddSystemProductModal, setShowAddSystemProductModal] =
    React.useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, productsList.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault();
      if (searchType === "product") {
        const product = productsList[highlightedIndex];
        searchProductDropdownRef.current.style.display = "none";
        setSearchProduct("");

        updateProductOnTab(
          tabIndex,
          {
            _id: product.details?._id,
            manufacturer: product.details?.manufacturer,
            name: product.details?.name,
            type: product.details?.type,
            size: product.details?.size,
            quantity: 1,
            price: product.basePrice,
            total: product.basePrice,
            wholesalePrice: product.wholesalePrice,
            retailPrice: product.retailPrice,
            expiryDate: "",
          },
          tabs,
          setTabs
        );
      } else {
        const supplier = suppliersList[highlightedIndex];
        searchSupplierDropdownRef.current.style.display = "none";
        setSearchSupplier("");
        setTabs((prevTabs) => {
          const newTabs = [...prevTabs];
          newTabs[tabIndex].supplier = {
            name: supplier.name,
            id: supplier._id,
            phoneNumber: supplier.phoneNumbers[0],
          };
          return newTabs;
        });
      }
    }
  };

  const { account } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (searchProduct.length >= 1) {
      searchProductDropdownRef.current.style.display = "block";
      setProductsList(
        branch?.products.filter((product) =>
          product.details?.name
            .toLowerCase()
            .includes(searchProduct.toLowerCase())
        )
      );

      setHighlightedIndex(-1);
    } else searchProductDropdownRef.current.style.display = "none";
  }, [branch?.products, searchProduct]);

  React.useEffect(() => {
    if (searchSupplier.length >= 1) {
      searchSupplierDropdownRef.current.style.display = "block";
      setSuppliersList(
        suppliers.filter((supplier) =>
          supplier.name.toLowerCase().includes(searchSupplier.toLowerCase())
        )
      );

      setHighlightedIndex(-1);
    } else searchSupplierDropdownRef.current.style.display = "none";
  }, [searchProduct, searchSupplier, suppliers]);

  React.useEffect(() => {
    if (branch.products) return setProductsList(branch?.products);
    dispatch(getBranch(branch._id));
  }, [branch._id, branch.products, dispatch]);

  React.useEffect(() => {
    // Close main dropdown when clicked outside
    const handleOutsideClick = (e) => {
      if (!searchProductDropdownRef.current.contains(e.target)) {
        searchProductDropdownRef.current.style.display = "none";
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className=" sales-tab">
      <Modal
        open={showAddSupplierModal}
      onClose={() => setShowAddSupplierModal(false)}
      >
        <AddSupplierModal
          setShowAddSupplierModal={setShowAddSupplierModal}
          showAddSupplierModal={showAddSupplierModal}
          branchId={branch._id}
        />
      </Modal>

      <Modal open={showProductModal} onClose={() => setShowProductModal(false)}>
        <AddProduct
          setShowProductModal={setShowProductModal}
          branchId={branch?._id}
        />
      </Modal>

      <Modal
        open={showAddSystemProductModal}
        onClose={() => setShowAddSystemProductModal(false)}
      >
        <AddSystemProduct
          setShowAddSystemProductModal={setShowAddSystemProductModal}
          branchId={branch?._id}
        />
      </Modal>
      {showQuantityPriceModal && (
        <RIChangeParamsModal
          showQuantityPriceModal={showQuantityPriceModal}
          setShowQuantityPriceModal={setShowQuantityPriceModal}
          tab={tab}
          setTabs={setTabs}
          tabIndex={tabIndex}
          productIndex={productIndex}
          setProductIndex={setProductIndex}
          tabs={tabs}
          selectedInput={selectedInput}
        />
      )}
      {showConfirmReceivedStockModal && (
        <ConfirmReceivedStockModal
          showConfirmReceivedStockModal={showConfirmReceivedStockModal}
          setShowConfirmReceivedStockModal={setShowConfirmReceivedStockModal}
          setTabs={setTabs}
          tabs={tabs}
          tab={tab}
          tabIndex={tabIndex}
          branchId={branch._id}
        />
      )}

      <header className="flex space-between align-end sales-tab-header ">
        <div className=" flex pointer">
          <DatePicker
            className=""
            style={{ width: "100%" }}
            options={{
              maxDate: new Date(),
            }}
            placeholder="Select Invoice Date"
            onChange={(date) => {
              date = new Date(date).toJSON().slice(0, 10);
              setTabs((prevTabs) => {
                const newTabs = [...prevTabs];
                newTabs[tabIndex].invoiceDate = date;
                return newTabs;
              });
            }}
            value={tab.invoiceDate}
          />
        </div>

        <div className="flex gap-1 align-center " style={{ height: "100%" }}>
          <div className="relative">
            <div
              className="custom-input-wrapper grey lighten-4 flex "
              style={{ minWidth: "max(300px, 35vw)" }}
            >
              <span className="material-symbols-outlined">search</span>
              <input
                type="text"
                className="browser-default
               custom-input grey lighten-4"
                placeholder="Search for product..."
                ref={inputRef}
                value={searchProduct}
                onChange={(e) => {
                  setSearchType("product");
                  setSearchProduct(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>

            <article className="custom-dropdown" ref={searchProductDropdownRef}>
              {
                <ul className="collection">
                  {productsList?.length === 0 ? (
                    <li className="collection-item">
                      No Products matching "{searchProduct}"
                    </li>
                  ) : (
                    productsList?.map((product, index) => (
                      <li
                        className={`collection-item flex space-between align-center hoverable gap-1 ${
                          highlightedIndex === index ? "highlighted" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          searchProductDropdownRef.current.style.display =
                            "none";
                          updateProductOnTab(
                            tabIndex,
                            {
                              _id: product.details?._id,
                              manufacturer: product.details?.manufacturer,
                              name: product.details?.name,
                              type: product.details?.type,
                              size: product.details?.size,
                              quantity: 1,
                              price: product.basePrice,
                              total: product.basePrice,
                              wholesalePrice: product.wholesalePrice,
                              retailPrice: product.retailPrice,
                              expiryDate: "",
                            },
                            tabs,
                            setTabs
                          );
                        }}
                      >
                        <div>
                          <label className="uppercase">
                            {product.details?.manufacturer}
                          </label>
                          <div className="flex gap-1">
                            <p className="uppercase">{product.details?.name}</p>
                            <p>{product.details?.size}</p>
                            <label className="grey-text">
                              {product.details?.type}
                            </label>
                          </div>
                        </div>

                        <div className="flex  gap-1">
                          <div>
                            <label>Last Received Price </label>
                            <p>{amount(product.basePrice)}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              }
            </article>
          </div>

          <Dropdown
            trigger={<i className="material-symbols-outlined ">more_vert</i>}
          >
            <span onClick={() => setShowAddSystemProductModal(true)}>
              Add System Product
            </span>

            <span onClick={() => setShowProductModal(true)}>
              Add New Product
            </span>
          </Dropdown>
        </div>

        <div
          className="flex gap-1 align-center select-supplier-input"
          style={{ height: "100%" }}
        >
          <div className="relative">
            <div
              className="custom-input-wrapper grey lighten-4 flex "
              style={{ minWidth: "max(300px, 35vw)" }}
            >
              <span className="material-symbols-outlined">search</span>
              <input
                type="text"
                className="browser-default
               custom-input grey lighten-4"
                placeholder="Select a supplier"
                ref={searchSupplierInputRef}
                value={searchSupplier}
                onChange={(e) => {
                  if (searchType === "product") setSearchType("supplier");
                  setSearchSupplier(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>

            <article
              className="custom-dropdown"
              ref={searchSupplierDropdownRef}
            >
              {
                <ul className="collection">
                  {suppliersList?.length === 0 ? (
                    <li className="collection-item">
                      No suppliers matching "{searchSupplier}"
                    </li>
                  ) : (
                    suppliersList?.map((supplier, index) => (
                      <li
                        className={`collection-item flex space-between align-center hoverable ${
                          highlightedIndex === index ? "highlighted" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          searchSupplierDropdownRef.current.style.display =
                            "none";
                          setSearchSupplier("");
                          setTabs((prevTabs) => {
                            const newTabs = [...prevTabs];
                            newTabs[tabIndex].supplier = {
                              name: supplier.name,
                              id: supplier._id,
                              phoneNumber: supplier.phoneNumbers[0],
                            };
                            newTabs[tabIndex].title = supplier.name;
                            return newTabs;
                          });
                        }}
                      >
                        <div>
                          <p>{supplier?.name}</p>
                          <p>{supplier?.phoneNumber}</p>
                        </div>

                        <div className="flex  gap-1">
                          <div>
                            <label>PhoneNumber</label>
                            <p>{supplier?.phoneNumbers[0]}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              }
            </article>
          </div>
          <Dropdown
            trigger={<i className="material-symbols-outlined ">more_vert</i>}
          >
            <span onClick={() => setShowAddSupplierModal(true)}>
              Add Supplier
            </span>
          </Dropdown>
        </div>
      </header>

      <main className="sales-main">
        <section>
          <div className="flex space-between">
            <div>
              <p>{tab.supplier?.name}</p>
            </div>

            {tab.saved && (
              <div className="pink sales-type-chip">Update Stock </div>
            )}
          </div>
          {tab.products?.length === 0 ? (
            <div
              className="flex justify-center align-center"
              style={{ height: "100%" }}
            >
              <div className="flex column align-center grey-text">
                <i className="material-symbols-outlined">list_alt</i>
                <h5>No Products Added</h5>
                <>Start adding products by searching for them</>
              </div>
            </div>
          ) : (
            tab.products?.map((item, index) => {
              return (
                <div key={index}>
                  <div className="product-card ">
                    <div className="product-details">
                      <p>{index + 1}</p>
                      <div>
                        <p className="small-text uppercase">
                          {item.manufacturer}
                        </p>
                        <div className="flex gap-1">
                          <p className="uppercase bold">{item.name}</p>
                          <p>{item.size}</p>
                          <p className="grey-text">{item.type}</p>
                        </div>
                      </div>
                    </div>

                    {account.permissions?.includes("update-sale") && (
                      <div className="uppercase">
                        <div className="flex column gap-1 align-center space-between">
                          <p className="small-text grey-text">In Stock</p>
                          <h6
                            className={
                              branch.products?.find(
                                (p) => p.details?._id === item._id
                              )?.stock < item.quantity
                                ? "red-text"
                                : "green-text"
                            }
                          >
                            {
                              branch.products?.find(
                                (p) => p.details?._id === item._id
                              )?.stock
                            }
                          </h6>
                        </div>
                      </div>
                    )}

                    <div className="">
                      <div
                        className="flex column gap-1 align-center  space-between"
                        style={{ width: "fit-content" }}
                      >
                        <p className="small-text grey-text">Quantity</p>

                        <div className="flex gap-1 align-center">
                          <button
                            className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                            onClick={() => {
                              increaseProductQuantity(
                                tabIndex,
                                item,
                                tabs,
                                setTabs,
                                index
                              );
                            }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                          </button>

                          <h6
                            onClick={() => {
                              setSelectedInput("quantity");
                              setProductIndex(index);
                              setShowQuantityPriceModal(true);
                            }}
                          >
                            {item.quantity}
                          </h6>

                          <button
                            className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                            onClick={() => {
                              decreaseProductQuantity(
                                tabIndex,
                                item,
                                tabs,
                                setTabs,
                                index
                              );
                            }}
                          >
                            <span className="material-symbols-outlined">
                              remove
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="flex column gap-1 align-center space-between">
                        <p className="small-text grey-text">Unit Price</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("price");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amount(item.price)}
                        </h6>
                      </div>
                    </div>

                    <div className="flex column gap-1 align-center space-between">
                      <p className="small-text grey-text">Subtotal</p>
                      <h6
                        role="button"
                        onClick={() => {
                          setSelectedInput("total");
                          setProductIndex(index);
                          setShowQuantityPriceModal(true);
                        }}
                      >
                        {amountWithoutCurrency(item.total)}
                      </h6>
                    </div>
                    {branch.wholesale && (
                      <div className="flex column gap-1 align-center space-between">
                        <p className="small-text grey-text">Wholesale Price</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("wholesalePrice");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amountWithoutCurrency(item.wholesalePrice)}
                        </h6>
                      </div>
                    )}

                    {branch.retail && (
                      <div className="flex column gap-1 align-center space-between">
                        <p className="small-text grey-text">Retail Price</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("retailPrice");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amountWithoutCurrency(item.retailPrice)}
                        </h6>
                      </div>
                    )}

                    {/* <div className="">
                      <p className="small-text grey-text">
                        Closest Expiry Date
                      </p>

                      <DatePicker
                        className="browser-default datepicker"
                        style={{ width: "100%" }}
                        options={{}}
                        placeholder="Expiry Date"
                        onChange={(date) => {
                          date = new Date(date).toJSON().slice(0, 10);

                          updateExpiryDate(
                            tabIndex,
                            {
                              ...item,
                              expiryDate: date,
                            },
                            tabs,
                            setTabs,
                            index
                          );
                        }}
                        value={item.expiryDate}
                      />
                    </div> */}

                    <div>
                      <button
                        className="btn-floating waves-effect waves-light  flex justify-center align-center red"
                        onClick={() => {
                          removeProduct(tabIndex, item, tabs, setTabs, index);
                        }}
                      >
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="product-card-mobile">
                    <div className="flex space-between">
                      <div className="product-details">
                        <p>{index + 1}</p>
                        <div>
                          <p className="small-text">{item.manufacturer}</p>
                          <p>{item.name}</p>
                        </div>
                      </div>

                      <div className="">
                        <div
                          className="flex column gap-1 align-center  space-between"
                          style={{ width: "fit-content" }}
                        >
                          <p className="small-text grey-text">Quantity</p>

                          <div className="flex gap-1 align-center">
                            <button
                              className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                              onClick={() => {
                                increaseProductQuantity(
                                  tabIndex,
                                  item,
                                  tabs,
                                  setTabs,
                                  index
                                );
                              }}
                            >
                              <span className="material-symbols-outlined">
                                add
                              </span>
                            </button>

                            <h6
                              onClick={() => {
                                setSelectedInput("quantity");
                                setProductIndex(index);
                                setShowQuantityPriceModal(true);
                              }}
                            >
                              {item.quantity}
                            </h6>

                            <button
                              className="btn-floating waves-effect waves-light  flex justify-center align-center black"
                              onClick={() => {
                                decreaseProductQuantity(
                                  tabIndex,
                                  item,
                                  tabs,
                                  setTabs,
                                  index
                                );
                              }}
                            >
                              <span className="material-symbols-outlined">
                                remove
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex space-between mt-1">
                      <div className="">
                        <p className="small-text grey-text">Unit Price</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("price");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amount(item.price)}
                        </h6>
                      </div>

                      <div className="">
                        <p className="small-text grey-text">Subtotal</p>
                        <h6
                          role="button"
                          onClick={() => {
                            setSelectedInput("total");
                            setProductIndex(index);
                            setShowQuantityPriceModal(true);
                          }}
                        >
                          {amountWithoutCurrency(item.total)}
                        </h6>
                      </div>

                      {branch.wholesale && (
                        <div className="">
                          <p className="small-text grey-text">
                            Wholesale Price
                          </p>
                          <h6
                            role="button"
                            onClick={() => {
                              setSelectedInput("wholesalePrice");
                              setProductIndex(index);
                              setShowQuantityPriceModal(true);
                            }}
                          >
                            {amountWithoutCurrency(item.wholesalePrice)}
                          </h6>
                        </div>
                      )}

                      {branch.retail && (
                        <div className="">
                          <p className="small-text grey-text">Retail Price</p>
                          <h6
                            role="button"
                            onClick={() => {
                              setSelectedInput("retailPrice");
                              setProductIndex(index);
                              setShowQuantityPriceModal(true);
                            }}
                          >
                            {amountWithoutCurrency(item.retailPrice)}
                          </h6>
                        </div>
                      )}
                    </div>

                    <div className="flex align-center space-between gap-1">
                      <div className="flex column gap-1 align-center space-between">
                        <p className="small-text grey-text">
                          Closest Expiry Date
                        </p>
                        <div className="flex align-center gap-1 datepicker white">
                          <DatePicker
                            className="browser-default "
                            onChange={(date) => {
                              date = new Date(date).toJSON().slice(0, 10);

                              updateExpiryDate(
                                tabIndex,
                                {
                                  ...item,
                                  expiryDate: date,
                                },
                                tabs,
                                setTabs,
                                index
                              );
                            }}
                            value={item.expiryDate}
                          />
                          <span className="material-symbols-outlined">
                            calendar_month
                          </span>
                        </div>
                      </div>

                      <div>
                        <button
                          className="btn-floating waves-effect waves-light  flex justify-center align-center red"
                          onClick={() => {
                            removeProduct(tabIndex, item, tabs, setTabs, index);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            delete
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </section>

        <aside>
          <div className=" ml-2 flex justify-end ">
            {tabs?.length > 1 && (
              <i
                className="material-symbols-outlined red-text"
                onClick={() => {
                  if (tabs.length === 1)
                    return notify("Cannot close last tab", "info");
                  let newTabs = [...tabs];
                  newTabs.splice(tabIndex, 1);
                  setTabs(newTabs);
                }}
              >
                close
              </i>
            )}
          </div>
          <section className="sales-subtotal">
            <div className="flex space-between align-center">
              <p className="grey-text">Subtotal</p>
              <h6 className="bold">{calculateTotal(tab)}</h6>
            </div>
            <div className="flex space-between align-center">
              <p className="grey-text">Discount</p>
              <h6 className="bold">0.00</h6>
            </div>
            <div className="flex space-between align-center">
              <p className="grey-text">Tax</p>
              <h6 className="bold">0.00</h6>
            </div>
          </section>

          <section className="sales-subtotal">
            <div className="flex space-between align-center">
              <p className="grey-text">Total Amount</p>
              <h5 className="bold">{amount(calculateTotal(tab))}</h5>
            </div>
          </section>

          <div className="flex mt-1 gap-1">
            {tab.saved ? (
              <button
                className="btn-large pink"
                style={{ width: "100%" }}
                onClick={() => {
                  setShowConfirmReceivedStockModal(true);
                }}
                disabled={tab.products.length === 0}
              >
                Update Stock Received
              </button>
            ) : (
              <button
                className="btn-large"
                style={{ width: "100%" }}
                onClick={() => {
                  setShowConfirmReceivedStockModal(true);
                }}
                disabled={tab.products.length === 0}
              >
                Received Stock
              </button>
            )}
          </div>
        </aside>
      </main>
    </div>
  );
};

export default RIproductListSection;
